/* eslint-disable import/prefer-default-export */
import { defineStore } from 'pinia'

export const useShoppingCartStore = defineStore('ShoppingCartStore', {
    state: () => ({
        cart: [],
    }),

    getters: {

    },

    actions: {
        setShoppingCart(data) {
            this.cart = data
            localStorage.setItem('cart', JSON.stringify(data))
        },
        resetState() {
            console.log('resetState')

            //   this.role = ''
        },
    },
})
