<template>
  <div>
    <v-card
      class=" d-flex"
      flat
      tile
    >
      <v-card
        class=" pa-2"

        tile
        flat
      >
        <v-btn

          color="primary"
          @click="activate"
        >
          <v-icon
            dark
            left
          >
            {{ icons.mdiFilterOutline }}
          </v-icon>
          <span
            v-if="$vuetify.breakpoint.smAndUp"
            class="tw-text-lg"
          >Filters</span>
        </v-btn>
      </v-card>

      <v-card
        v-if=" $vuetify.breakpoint.smAndUp"
        class="pa-2"
        flat
        tile
      >
        <v-chip
          v-if="(selectedStatus && selectedStatus.name)"
          close
          @click:close="clearFilter"
        >
          {{ selectedStatus.name }}
        </v-chip>
        <v-chip
          v-if="(filterStore.rxListFilter && filterStore.rxListFilter.name)"
          color="success"
        >
          <v-icon left>
            {{ icons.mdiStarCheck }}
          </v-icon>
          {{ filterStore.rxListFilter.name }}
        </v-chip>
      </v-card>

      <v-card
        class="ml-auto pa-2"
        tile
        flat
      >
        <v-text-field
          v-model="search"
          :append-icon="icons.mdiMagnify"
          label="Search"
          single-line
          hide-details
          dense
          outlined
        ></v-text-field>
      </v-card>
    </v-card>
    <div class="shrink">
      <v-expand-transition duration="2000">
        <v-card
          v-show="showCard"
          flat
          class="mx-auto"
        >
          <v-row
            class=" tw-py-5"
          >
            <v-col
              cols="12"
              md="3"
            >
              <span class="tw-pl-3 tw-text-base tw-font-black">Status (WIP)</span>
              <v-divider class="tw-mt-3"></v-divider>
              <v-list
                dense
                outlined
                class="my-list tw-mt-6"
              >
                <v-list-item-group
                  v-model="filterData"
                  color="warning"
                >
                  <template
                    v-for="(item, i) in filteredStatus"
                  >
                    <v-list-item
                      :key="item.value"
                      @click="filterByStatus(item)"
                    >
                      <template #default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }} <span v-if="item.value === 'up' || item.value ==='down' ">{{ item.value === 'up' ? '📈' : '📉' }}</span>
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                          <v-icon v-if="!active">
                            {{ icons.mdiRadioboxBlank }}
                          </v-icon>

                          <v-icon
                            v-else
                            color="warning"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span class="tw-pl-3 tw-text-base tw-font-black">View</span>
              <v-divider class="tw-mt-3"></v-divider>
              <v-list
                dense
                outlined
                class="my-list tw-mt-6"
              >
                <v-list-item-group
                  v-model="activeView"
                  color="warning"
                >
                  <template
                    v-for="(item, i) in viewsList"
                  >
                    <v-list-item
                      :key="item.value"
                    >
                      <!-- <v-list-item
                      :key="item.value"
                      @click="filterByTarget(item)"
                    > -->
                      <template #default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-list-item-action-text v-text="item.action"></v-list-item-action-text>

                          <v-icon v-if="!active">
                            {{ icons.mdiRadioboxBlank }}
                          </v-icon>

                          <v-icon
                            v-else
                            color="warning"
                          >
                            {{ icons.mdiCloseCircleOutline }}
                          </v-icon>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span class="tw-pl-3 tw-text-base tw-font-black">Minimum Target Value ($)</span>
              <v-divider class="tw-mt-3"></v-divider>
              <v-slider
                v-model="filterStore.threshold"
                class="tw-mt-12"
                hint="Adjust Threshold"
                max="15000"
                min="0"
                thumb-label="always"
                step="1000"
                ticks="always"
                tick-size="2"
                @change="filterByTarget"
              ></v-slider>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <span class="tw-pl-3 tw-text-base tw-font-black">Taro Market Share < {{ filterStore.marketShare }}%</span>
              <v-divider class="tw-mt-3"></v-divider>
              <v-slider
                v-model="filterStore.marketShare"
                class="tw-mt-12"
                hint="Adjust Threshold"
                max="100"
                min="0"
                thumb-label="always"
                step="10"
                ticks="always"
                tick-size="2"
                @change="filterByTarget"
              ></v-slider>
            </v-col>
          </v-row>
        </v-card>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import {
    mdiStar,
    mdiStarOutline,
    mdiRadioboxBlank,
    mdiRadioboxMarked,
    mdiMagnify,
    mdiCloseCircleOutline,
    mdiStarCheck,
    mdiFilterOutline
} from '@mdi/js'
import { useFilterStore } from '@/stores/FilterStore'

export default {
    name: 'DataFilter',
    components: {},
    props: {
        showCardProp: {
            type: Boolean,
            default: false,
        },
    },
    setup() {
        const filterStore = useFilterStore()
        const search = ref('')
        const showCard = ref(false)
        const filterData = ref({})
        const activeView = ref(filterStore.rxListFilter.index == null ? null : filterStore.rxListFilter.index)
        const threshold = ref(2000)

        const activateLocal = () => {}

        return {
            filterStore,
            threshold,
            activeView,
            search,
            filterData,
            showCard,
            icons: {
                mdiStar,
                mdiStarOutline,
                mdiRadioboxBlank,
                mdiRadioboxMarked,
                mdiMagnify,
                mdiCloseCircleOutline,
                mdiStarCheck,
                mdiFilterOutline
            },
            activateLocal,
        }
    },
    data() {
        return {
            selectedStatus: {},
            selectedGroupBy: {},
            list3: [],
            list4: [],
            appliedFilter: {},
            viewsList: [
                {
                    name: 'Targets Only',
                    value: 'targetView',
                },
                {
                    name: 'Ordering Intervals',
                    value: 'orderingIntervals',
                },
            ],
            status: [
                {
                    name: 'Trending Up',
                    value: 'up',
                    color: 'success',
                },
                {
                    name: 'Trending Down',
                    value: 'down',
                    color: 'error',
                },
                {
                    name: 'Single Source',
                    value: 'SS',
                },
                {
                    name: 'Frequent Backorder',
                    value: 'BO',
                },

                // {
                //     name: 'Transfer',
                //     value: 'TRN',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Amended',
                //     value: 'AMD',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Hold',
                //     value: 'HOL',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Complete',
                //     value: 'COM',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Cancelled',
                //     value: 'CAN',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Pending',
                //     value: 'PEN',
                //     dbType: 'nexxsys',
                // },
                // {
                //     name: 'Incomplete',
                //     value: 'INC',
                //     dbType: 'nexxsys',
                // },
            ],
        }
    },

    computed: {
        filteredStatus() {
            return this.status

            //   return this.status.filter(item => item.dbType === this.userStore.getPharmDb)
        },
    },
    watch: {
        threshold: {
            handler(val) {
                this.threshold = this.filterStore.threshold

                // this.$emit('filterByThreshold', val)
            },
            immediate: true,
            deep: true,
        },
        showCardProp: {
            handler(val) {
                this.showCard = val
            },

            immediate: true,
        },
        filterData: {
            handler(val) {
                this.selectedStatus = this.filteredStatus[val]

                // this.$emit('filterData', this.selectedStatus)
            },
            immediate: true,
            deep: true,
        },

        activeView: {
            handler(val) {
                const view = this.viewsList[val] == null ? {} : { ...this.viewsList[val], index: val }
                this.filterStore.setrxListFilter(view)
                this.$emit('filterByTarget', view)
            },

            // immediate: true,
            deep: true,
        },
        search: {
            handler(val) {
                this.$emit('searchTable', val)
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        filterByThreshold() {
            if (this.filterStore.rxListFilter.index === 0) {
                this.$emit('filterByThreshold', { viewFilterOn: true })
            } else {
                this.$emit('filterByThreshold', { viewFilterOn: false })
            }
        },
        filterByTarget(item) {
            this.$emit('filterByTarget', item)
        },
        clearFilter() {
            this.selectedStatus = undefined
            this.filterData = undefined
            this.$emit('filterData', this.selectedStatus)
        },
        activate() {
            this.$emit('updateShowCardProp', !this.showCard)

            // this.showCard = !this.showCard
            this.activateLocal()
        },
        populatePrescriptionsList(dateSelected) {
            this.currentDate = dateSelected !== undefined ? dateSelected : this.currentDate

            this.$emit('populatePrescriptionsList', this.currentDate)
        },

        filterByStatus(status) {
            this.$emit('filterByStatus', status)
        },
    },
}
</script>

<style lang="scss" scoped>
.my-list {
  padding: 0 !important;
}
</style>
