import { currencyConvert } from '@/util/functions'

const pharmacyTableHeaders = [
    {
        text: 'Pharmacy',
        value: 'pharmacyName',
        align: 'left',
        width: '70%',
    },
    {
        text: 'Generic Potential',
        value: 'sales',
        align: 'left',
        width: '20%',
        formatter(value) {
            return `${currencyConvert(parseInt(value * 100, 10) / 100)}`
        },
    },
]

export default pharmacyTableHeaders
