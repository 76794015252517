<template>
  <div>
    <stat-cards></stat-cards>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <data-filter
              id="data-filter"
              :show-card-prop="showCard"
              @filterData="filterData"
              @updateShowCardProp="updateShowCardProp"
              @populatePrescriptionsList="filterByTarget($event)"
              @searchTable="searchTable"
              @filterByTarget="filterByTarget"
            ></data-filter>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :key="rxTodayKey"
          v-model="filterStore.selectedDrugs"
          :loading="loading"
          :height="$vuetify.breakpoint.smAndDown ? '100%' : height - 450"
          dense
          :search="search"
          :headers="pharmacyMoleculeListTableHeaders"
          :items="moleculePerMonthTableData"
          :items-per-page="rowsPerPage"
          :footer-props="{
            'items-per-page-options': [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 50, 100, -1],
          }"
          item-key="genericProductNumber"
          class="table-kitchen-sink"
          single-select
          sort-by="salesSum"
          sort-desc
        >
          <template #[`item.genericName`]="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  plain
                  v-on="on"
                  @click="openDialogModal(item)"
                >
                  <v-icon
                    color="success"
                    medium
                    @click="openDialogModal(item)"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span class="tw-underline">{{ item.genericName }}</span>
                </v-btn>
              </template>
              <span>To Form Hub</span>
            </v-tooltip>
          </template>
          <template #item.Selected="{ item }">
            <v-icon
              v-if="item.genericProductNumber === filterStore.selectedDrugs[0]?.genericProductNumber"
              color="success"
              large
            >
              {{ icons.mdiCheckBold }}
            </v-icon>
          </template>
          <template #item.target="{ item }">
            <v-icon
              v-if="item.target"
              color="success"
            >
              {{ icons.mdiStarCheck }}
            </v-icon>
          </template>
          <template #item.DailyQtyDispenseSum="{ item }">
            {{ item.DailyQtyDispenseSum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}
          </template>
          <template #item.marketShare="{ item }">
            {{ item.marketShare }}%
            <v-progress-linear
              :value="item.marketShare"
              :color="item.marketShare > 50 ? 'success' : 'error'"
              height="10"
            ></v-progress-linear>
          </template>
          <template
            v-for="header in pharmacyMoleculeListTableHeaders.filter(header => header.hasOwnProperty('formatter'))"
            #[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template #footer>
            <v-card
              class="mt-6 d-flex"
              flat
              tile
            >
              <v-card
                class="mr-auto pa-2"
                tile
                flat
              >
                <!-- <p class="tw-text-black dark:tw-text-white">
                  <span class="tw-text-green-800 dark:tw-text-white"> Taro Potential: {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfSales) }} </span>
                </p>
                <p class="tw-text-black dark:tw-text-white">
                  <span class="tw-text-green-800 dark:tw-text-white"> Taro Total: {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfTaroSales) }} </span>
                </p>
                <p class="tw-text-black dark:tw-text-white">
                  <span class="tw-text-green-800 dark:tw-text-white"> Taro Total: {{ dataStore.stats.totalSalesNum.marketShare }} </span>
                </p> -->
              </v-card>
              <v-card
                class="pa-2"
                flat
                tile
              >
                <p class="tw-text-black dark:tw-text-white">
                  <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="info"
                    icon
                    @click="filterByTarget()"
                  >
                    <v-icon large>
                      {{ icons.mdiRefresh }}
                    </v-icon>
                    <template #loader>
                      <span class="custom-loader">
                        <v-icon
                          large
                          light
                        >{{ icons.mdiCached }}</v-icon>
                      </span>
                    </template>
                  </v-btn>
                  <span class="tw-text-green-800 dark:tw-text-white"> Last update: {{ currentTimeStamp }} </span>
                </p>
              </v-card>
            </v-card>
          </template>
          <template slot="body.append">
            <tr v-if="dataStore.stats">
              <td></td>
              <td></td>
              <td>
                <span class="tw-text-xl tw-text-black dark:tw-text-white"> Totals: </span>
              </td>
              <td></td>
              <td>
                <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                  {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfSales) }}
                </span>
              </td>
              <td>
                <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                  {{ currencyConvert(dataStore.stats.totalSalesNum.sumOfTaroSales) }}
                </span>
              </td>
              <td>
                <span class="tw-text-xl tw-text-green-900 dark:tw-text-green-600">
                  {{ dataStore.stats.totalSalesNum.marketShare }}
                </span>
              </td>
              <td></td>
              <td></td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
      <dialog-full-screen
        :open-dialog="openDialog"
        @deActivateDialogFromChild="deActivateDialog"
      ></dialog-full-screen>
    </v-card>
  </div>
</template>

<script>
import { useWindowSize } from '@vueuse/core'
import { ref } from 'vue'
import {
    mdiRefresh,
    mdiCached,
    mdiTrendingDown,
    mdiTrendingUp,
    mdiStarCheck,
    mdiCheckBold,
    mdiPencilOutline,
} from '@mdi/js'
import { pharmacyMoleculeListTableHeaders } from '@/assets/headers/index'
import DataFilter from '@/components/filters/DataFilter.vue'
import { useFilterStore } from '@/stores/FilterStore'
import { roundInt, currentDateTime, currencyConvert } from '@/util/functions'
import { useDataStore } from '@/stores/Data'
import { useShoppingCartStore } from '@/stores/ShoppingCartStore'
import statCards from '@/components/tables/statCards.vue'

export default {
    name: 'PharmacyMoleculeListTable',
    components: {
        DialogFullScreen: () => import('@/components/dialog/DialogFullScreen.vue'),
        DataFilter,
        statCards
    },
    setup() {
        const loading = ref(false)
        const openDialog = ref(false)
        const shoppingCartStore = useShoppingCartStore()
        const dataStore = useDataStore()
        const selected = ref([])
        const currentTimeStamp = currentDateTime()
        const search = ref('')
        const filterStore = useFilterStore()
        const { width, height } = useWindowSize()
        const rxTodayKey = ref(0)
        const moleculePerMonthTableData = ref([])
        const filterData = ref({})
        const taroSales = ref([])
        const rowHeight = 50 // adjust this to the actual height of each row

        return {
            currencyConvert,
            loading,
            rowHeight,
            openDialog,
            shoppingCartStore,
            dataStore,
            selected,
            currentTimeStamp,
            roundInt,
            taroSales,
            search,
            filterStore,
            filterData,
            width,
            height,
            rxTodayKey,
            moleculePerMonthTableData,
            icons: {
                mdiPencilOutline,
                mdiCheckBold,
                mdiStarCheck,
                mdiRefresh,
                mdiCached,
                mdiTrendingDown,
                mdiTrendingUp,
            },
        }
    },
    data() {
        return {
            refreshInterval: null,
            showCard: false,

            // attrs: {
            //     class: 'mb-6',
            //     boilerplate: true,
            //     elevation: 2,
            // },
            pharmacyMoleculeListTableHeaders,
            prescriptionsLength: 0,
            itemsPerPageOptions: [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 50, 100, -1],
        }
    },
    computed: {
        rowsPerPage() {
            return Math.floor((this.height - 400) / this.rowHeight)
        },
    },
    watch: {},
    mounted() {
        this.$root.$on('refresh-all-charts', async () => {
            // this.moleculePerMonthTableData = []

            // this.rxTodayKey += 1
            this.filterByTarget()
        })

        this.filterByTarget()
    },
    destroyed() {
        // this.$root.$off('refresh-all-charts')
    },
    methods: {
        openDialogModal(item) {
            // this.filterStore.selectedDrugs = item
            // add item to this.filterStore.selectedDrugs array
            this.filterStore.setSelectedDrugs([item])
            this.openDialog = true
        },
        activateDialog() {
            this.openDialog = true
        },
        deActivateDialog() {
            this.openDialog = false
        },
        filterByTarget(view) {
            this.loading = true
            this.moleculePerMonthTableData = this.dataStore.pharmacyMoleculeList
            this.moleculePerMonthTableData.map(item => {
                item.target = item.salesSum > this.filterStore.threshold && item.marketShare < this.filterStore.marketShare

                return item
            })

            if (this.filterStore.rxListFilter.value === 'targetView') {
                this.moleculePerMonthTableData = this.moleculePerMonthTableData.filter(
                    item => item.salesSum > this.filterStore.threshold && item.marketShare < this.filterStore.marketShare,
                )
            }
            this.currentTimeStamp = currentDateTime()
            this.loading = false
            this.rxTodayKey += 1
        },
        rowClick(item, row) {
            // console.log('🚀 ~ file: pharmacyMoleculeListTable.vue:212 ~ rowClick ~ item', item)
            // if (this.rowIndex.includes(row.index)) {
            //     this.rowIndex = this.rowIndex.filter(rowIndex => rowIndex !== row.index)
            //     row.select(false)
            // } else {
            //     row.select(true)
            //     this.rowIndex.push(row.index)
            // }
        },
        searchTable(search) {
            this.search = search
        },
        updateShowCardProp(showCard) {
            this.showCard = showCard
        },
    },
}
</script>

<style lang="scss" scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
