<template>
  <v-card
    outlined
    class="statistics-horizontal"
  >
    <div class="d-flex align-center">
      <v-avatar
        v-if="!$vuetify.breakpoint.mdAndDown"
        :color="iconColor"
        rounded
        size="44"
        class="elevation-3"
      >
        <v-icon
          size="30"
          :color="color"
        >
          {{ icon }}
        </v-icon>
      </v-avatar>

      <div class="ms-3">
        <span class="text-xs">{{ statTitle }}</span>
        <p class="mb-0 text-no-wrap">
          <span class="text-xl font-weight-semibold text--primary">{{ formattedStatistic }}</span>
          <!-- <v-icon
            size="20"
            :color="checkChange(change) ? 'success':'error'"
          >
            {{ checkChange(change) ? mdiChevronUp : mdiChevronDown }}
          </v-icon> -->
          <span
            class="text-xs font-weight-semibold"
            :class="changeClass"
          >{{ changeWithoutSign }}</span>
        </p>
      </div>
    </div>
  </v-card>
</template>

<script>
import { currencyConvert } from '@/util/functions'

export default {
    props: {
        statTitle: { type: String, default: '' },
        statistics: { default: '' },
        change: { type: String, default: '' },
        icon: { type: String, default: '' },
        color: { type: String, default: '' },
        currency: { type: Boolean, default: false },
    },
    computed: {
        iconColor() {
            return this.$vuetify.theme.dark ? '#312d4b' : '#fff'
        },
        formattedStatistic() {
            return this.currency ? currencyConvert(this.statistics) : this.statistics
        },
        changeWithoutSign() {
            return this.change.slice(1)
        },
        changeClass() {
            return this.change.charAt(0) === '+' ? 'success--text' : 'error--text'
        },
    },
}
</script>

<style lang="scss" scoped>
.statistics-horizontal {
  background-color: transparent !important;
  padding: 1.25rem 4.063rem 1.125rem 1.25rem;
}

.v-application {
  &.v-application--is-rtl {
    .statistics-horizontal {
      padding: 1.25rem 1.125rem 1.25rem 4.063rem;
    }
  }
}
</style>
